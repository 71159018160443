import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:frame-render", this.mountComponent);
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-render", this.mountComponent);
  }

  mountComponent() {
    window.ReactRailsUJS.mountComponents(this.element);
  }
}
