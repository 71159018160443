import { Controller } from "stimulus";
import TomSelect from "tom-select";
import { defaultConfig } from "../config/tom-select";

export default class extends Controller {
  static values = {
    allowCreate: Boolean,
    preventDelete: Boolean,
    disableSearch: Boolean,
    allowClearOptions: Boolean,
  };

  connect() {
    this.customDropdown = new TomSelect(this.element, this.config());
  }

  disconnect() {
    this.customDropdown.destroy();
  }

  addOptgroupLabel = function () {
    if (Object.keys(this.optgroups).length == 0) {
      return;
    }
    Object.keys(this.options).forEach((key) => {
      this.updateOption(this.options[key].value, {
        ...this.options[key],
        optgroupLabel: this.optgroups[this.options[key].optgroup].label,
      });
    });
  };

  renderItem = (data, escape) => {
    if (!data.optgroupLabel) {
      return "<div>" + escape(data.text) + "</div>";
    }

    return (
      "<div><strong>" +
      escape(data.optgroupLabel) +
      "</strong>: " +
      escape(data.text) +
      "</div>"
    );
  };

  config() {
    let config = Object.assign({}, defaultConfig, {
      create: this.allowCreateValue,
      maxItems: 1,
      onItemAdd: () => this.customDropdown.blur(),
      onDelete: () => {
        return !this.preventDeleteValue;
      },
      onInitialize: this.addOptgroupLabel,
      render: Object.assign({}, defaultConfig.render, {
        item: this.renderItem,
      }),
    });

    if (this.disableSearchValue) {
      config.controlInput = null;
    }

    if (this.allowCreateValue) {
      config.render.no_results = null;
    }

    config.searchField = ["text", "optgroupLabel"];

    this.addPlugins(config);

    return config;
  }

  disconnect() {
    // Since the HTML has been replaced, we need to overwrite
    // the contents of the revertSettings cache to we don't
    // end up with the wrong options.
    this.customDropdown.revertSettings = {
      innerHTML: this.element.innerHTML,
      tabIndex: this.element.tabIndex,
    };
    this.customDropdown.destroy();
  }

  addPlugins(config) {
    if (!this.hasAllowClearOptionsValue) {
      return;
    }

    if (this.allowClearOptionsValue == true) {
      config.plugins = {
        clear_button: {
          title: "Ta bort",
          html: (data) =>
            `<i class="fa fa-close clear-button" title="${data.title}"></i>`,
        },
      };
      return;
    }

    config.plugins = ["no_backspace_delete"];
  }
}
